






























import {Component, Model, Prop, VModel, Vue} from 'vue-property-decorator';
import {PropType} from "vue/types/options";

export type checkBoxType = "DEFAULT" | "SLIDER"

@Component(
	{
		inheritAttrs: false,
	}
)
export default class PortalCheckbox extends Vue {

  @VModel( {type: Boolean})
  private inputValue!: boolean;

  @Prop({default: "DEFAULT"})
  private mode!: checkBoxType;

  @Prop({required: false, default: function () {return;}})
  private change!: () => void

  @Prop({default: null})
  private name!: string | null;

  @Prop({default: null})
  private id!: string | null;

  @Prop({default: null})
  private label!: string | null;

  @Prop({default: null})
  private title!: string | null;

  @Prop({default: false})
  private required!: boolean;

  @Prop({default: ""})
  private vValidate!: string | Record<string, any>

  @Prop({default: false})
  disabled!: boolean;

  private applyChange() {
	  if (this.change) {
		  this.change()
	  }
  }

}
