






































import {Vue, Component, Prop} from 'vue-property-decorator';
import VacationRequest from "@/components/payroll/vacation/vcr/VacationRequest.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import {prettyEnum} from "@/utils/StringUtils";
import moment, {Moment} from "moment-timezone";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {SLR, SLRFilter, SLRStatus} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import SickLeaveRequest from "@/components/payroll/sickLeave/slr/SickLeaveRequest.vue";

@Component({
	components: {SickLeaveRequest, PaginationComponent, VacationRequest, BTPortalCollapse},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class EmploymentSLRList extends Vue {
	@Prop()
	private employment!: IEmployment

	private slrs: Array<SLR> = [];
	private reqTotalPages = 0
	private reqFilter = new SLRFilter(
		{
			employmentsId: [this.employment.id!],
		}
	)

	public created() {
		this.loadRequests()
	}

	private loadRequests() {
		Application.startLoading()
		return SickLeaveService.getRequestPageByFilter(this.reqFilter).then(
			res => {
				this.slrs = res.data.data
				this.reqTotalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	resolveColor(slr: SLR): string {
		switch (slr.status) {
			case SLRStatus.APPROVED:
			case SLRStatus.COMPLETED:return "#3ECB7F"
			case SLRStatus.REQUESTED:return "#FEDC00"
			case SLRStatus.REJECTED:return "#E14C44"
			case SLRStatus.CANCELLED: return "#818181"
		}
	}

	private changeReqPage(page: number){
		this.reqFilter.page = page - 1;
		this.loadRequests();
	}
}
