






































































import {Component, Vue, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "../../dto/UserDTO";
import RouteNames from "@/router/RouteNames";
import {Route} from "vue-router";
import {nodeMap, RouterTree, RouterTreeNode} from "@/router/RouterTree";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import {CHANGE_WORKSPACE_ID, CHANGE_WORKSPACE_TYPE, WORKSPACE_ID, WORKSPACE_TYPE} from "@/constants/Paths";
import Auth from "@/state/Auth";
import {Role} from "@/constants/Role";

const Auth_ = namespace("Auth");
const AppModule = namespace("App");

@Component
export default class SideMenu extends Vue {

  @Auth_.State("user")
  private currentUser!: UserDTO;

  @Auth_.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  @Auth_.Action
  private signOut!: () => void;

  @Auth_.Getter
  private isLoggedIn!: boolean;

  private node = RouterTree;

  private toggleWorkspaces = false;

  mounted() {
    this.updateMenu();
  }

  updateMenu() {
    const newElement = nodeMap.get(this.$route.name || null);
    this.node = (newElement?.children.length == 0 ? nodeMap.get(newElement.parentName) : newElement) as RouterTreeNode;
  }

  @Watch('$route')
  onRouteChanged(oldRoute: Route, newRoute: Route) {
    this.updateMenu();
    this.toggleWorkspaces = false;
  }

  logOut() {
    this.signOut();
    this.$router.push("/login");
  }

  goUp(){
    this.$router.push({name: this.node.parentName || RouteNames.HOME})
  }

  isAvailable(node: RouterTreeNode): boolean {
    if(this.isStateless(node) && node.role && !Auth.hasPermission(node.role)){
      return false;
    }
    if(node.isLoggedIn && !this.isLoggedIn){
      return false;
    }
    if(node.isLoggedOut && this.isLoggedIn){
      return false;
    }
    if(node.isAdmin && !this.hasAdministrativePrivileges){
      return false;
    }
    if(!node.availableForAdminOnSidePanelByDefault
        && this.hasAdministrativePrivileges
        && Workspaces.getCurrent?.userId == this.currentUser.id
        && this.$route.name != node.name
        && !node.hasChildren(this.$route.name as string)){
      return false;
    }
    if (!node.availableForEveryoneOnSidePanelByDefault
        && this.$route.name != node.name
        && !node.hasChildren(this.$route.name as string)) {
      return false;
    }
    if (!this.isStateless(node) && !node.availableForCompany && Workspaces.isCurrentWorkspaceCompany) {
      return false;
    }
    if (!this.isStateless(node) && !node.availableForPerson && Workspaces.isCurrentWorkspacePerson) {
      return false;
    }
    if(!this.isStateless(node) && !node.availableForEmployee && Workspaces.isCurrentWorkspaceSublimeEmployee){
      return false;
    }
    if(node.role && Workspaces.getCurrent?.userId != this.currentUser.id && Workspaces.getCurrent?.userId != this.currentUser.linkedUserId && !Auth.hasPermission(node.role)){
      return false;
    }
    return true;
  }

  isStateless(node: RouterTreeNode): boolean {
    return !(node.availableForCompany || node.availableForPerson || node.availableForEmployee);
  }

  private onSelect() {
    this.$emit("menuItemSelected");
  }

  selectWorkspace(ws: Workspace) {
    Workspaces.changeWorkspace(ws);
    this.toggleWorkspaces = false;
    const params = this.$route.params
    params[CHANGE_WORKSPACE_ID] = `${ws.id}`;
    params[CHANGE_WORKSPACE_TYPE] = `${ws.getPathElement}`;
    if(params[WORKSPACE_ID]){
      params[WORKSPACE_ID] = `${ws.id}`;
    }
    if(params[WORKSPACE_TYPE]){
      params[WORKSPACE_TYPE] = `${ws.getPathElement}`;
    }
    this.$router.push({name: `${this.$route.name}`, params: params}).catch(this.$void);
  }

  isAdmin(ws: Workspace) {
    return ws.type == WorkspaceType.SUBLIME_EMPLOYEE;
  }

}
