import {
    AllVacationFilter,
    AllVCRFilter,
    ApproveRequestPayload,
    ChangeStatusVCRRequestPayload,
    CreateVacationPayload, CreateVCRequestPayload, DecisionVCR, RejectRequestPayload,
    UpdateVacationCarryoverPayload,
    VacationBalance, VacationBalanceDetailForPeriod, VacationBalanceDetailsDTO, BalanceDetailsFilterForPeriod,
    VacationDto,
    VacationFilter, VCR, VCRFilter
} from "@/dto/payroll/VacationDTO";
import QueryUtils from "@/utils/QueryUtils";
import axios, {AxiosResponse} from "axios";
import Pageable from "@/dto/Pageable";
import {
    PAYROLL_VACATION_ALL_ENDPOINT,
    PAYROLL_VACATION_ENDPOINT, PAYROLL_VACATION_REQUEST_ALL_ENDPOINT,
    PAYROLL_VACATION_REQUEST_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

class VacationService {

    getPageOfVacations(filter: VacationFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<VacationDto>>(`${PAYROLL_VACATION_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getAllVacationsByFilter(filter: AllVacationFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Array<VacationDto>>(`${PAYROLL_VACATION_ALL_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getVacationBalance(employmentId: number) {
        return axios.get<VacationBalance>(`${PAYROLL_VACATION_ENDPOINT}/balance?employmentId=${employmentId}`, {headers: authHeader()})
    }

    createVacationRecord(req: CreateVacationPayload) {
        return axios.post<void>(`${PAYROLL_VACATION_ENDPOINT}`, req, {headers: authHeader()})
    }

    deleteVacationRecord(vacationId: number, employmentId: number) {
        return axios.delete(`${PAYROLL_VACATION_ENDPOINT}?vacationId=${vacationId}&employmentId=${employmentId}`, {headers: authHeader()})
    }

    updateVacationBalance(payload: UpdateVacationCarryoverPayload) {
        return axios.put(`${PAYROLL_VACATION_ENDPOINT}/balance`, payload, {headers: authHeader()})
    }

    getPageOfVacationRequests(reqFilter: VCRFilter) {
        const query = QueryUtils.objectToQueryString(reqFilter);
        return axios.get<Pageable<VCR>>(`${PAYROLL_VACATION_REQUEST_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getAllVacationRequestsByFilter(reqFilter: AllVCRFilter) {
        const query = QueryUtils.objectToQueryString(reqFilter);
        return axios.get<Array<VCR>>(`${PAYROLL_VACATION_REQUEST_ALL_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getVacationRequestById(id: number, reqFilter: VCRFilter) {
        const query = QueryUtils.objectToQueryString(reqFilter);
        return axios.get<Pageable<VCR>>(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/${id}${query}`, {headers: authHeader()})
    }

    requestVacation(payload: CreateVCRequestPayload) {
        return axios.post(`${PAYROLL_VACATION_REQUEST_ENDPOINT}`, payload, {headers: authHeader()})
    }

    changeStatus(payload: ChangeStatusVCRRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}`, payload, {headers: authHeader()})
    }

    rejectRequest(payload: RejectRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/reject`, payload, {headers: authHeader()})
    }

    approveRequest(payload: ApproveRequestPayload) {
        return axios.put(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/approve`, payload, {headers: authHeader()})
    }

    getBalanceDetails(balanceDetailsFilter: BalanceDetailsFilterForPeriod): Promise<AxiosResponse<Pageable<VacationBalanceDetailsDTO>>> {
        const query = QueryUtils.objectToQueryString(balanceDetailsFilter)
        return axios.get<Pageable<VacationBalanceDetailsDTO>>(`${PAYROLL_VACATION_ENDPOINT}/balance/details${query}`, {headers: authHeader()})
    }

    getBalanceDetailsForPeriod(filter: BalanceDetailsFilterForPeriod): Promise<AxiosResponse<VacationBalanceDetailForPeriod>> {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<VacationBalanceDetailForPeriod>(`${PAYROLL_VACATION_ENDPOINT}/balance/period_details${query}`, {headers: authHeader()})
    }

    getVcrById(id: number | string, customerId: number | null = null): Promise<AxiosResponse<VCR>> {
        const query = customerId ? `?customerId=${customerId}` : '';
        return axios.get<DecisionVCR>(`${PAYROLL_VACATION_REQUEST_ENDPOINT}/${id}${query}`, {headers: authHeader()})
    }

}

export default new VacationService()
