var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
			timesheet_day: true,
			timesheet_day_selected: _vm.isSelected,
			timesheet_day_vacation: _vm.isVacation || _vm.isLWOP,
			timesheet_day_sick_leave: _vm.isSickLeave || _vm.isUSL
		},on:{"click":function($event){return _vm.$emit('selectDay', _vm.day)}}},[_c('div',{staticClass:"timesheet_day__title",style:({
			paddingTop: _vm.isVacation || _vm.isSickLeave || _vm.isUSL || _vm.isLWOP ? '2px' : '12px'
		})},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment().localeData().weekdaysShort()[_vm.moment(this.day.date).weekday()]))]),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(_vm.day.date).format("D")))])]),_c('div',{staticClass:"timesheet_day__body position-relative",style:({
			paddingTop: _vm.isVacation || _vm.isSickLeave || _vm.isUSL || _vm.isLWOP ? '2px' : '15px',
			paddingBottom: _vm.isVacation || _vm.isSickLeave || _vm.isUSL || _vm.isLWOP? '7px' : '8px'
		})},[(_vm.isVacation)?_c('div',{staticClass:"text-center"},[_vm._v(" Vacation ")]):_vm._e(),(_vm.isLWOP)?_c('div',{staticClass:"text-center"},[_vm._v(" Leave Without Pay ")]):_vm._e(),(_vm.isUSL)?_c('div',{staticClass:"text-center"},[_vm._v(" Unpaid Sick Leave ")]):_vm._e(),(_vm.isSickLeave)?_c('div',{staticClass:"text-center"},[_vm._v(" Sick Leave ")]):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.isClosed)?_c('span',[_vm._v(_vm._s(_vm.day.duration))]):_c('font-awesome-icon',{attrs:{"icon":"clock","size":"lg"}})],1)])]),_c('div',{staticClass:"timesheet_day_mobile",class:{
		 timer: !(_vm.isVacation || _vm.isSickLeave || _vm.isUSL || _vm.isLWOP) && !_vm.isClosed,
		 start: !(_vm.isVacation || _vm.isSickLeave || _vm.isUSL || _vm.isLWOP) && _vm.isClosed},on:{"click":_vm.processClick}},[_c('div',[(_vm.isVacation)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Vacation ")]):(_vm.isSickLeave)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Sick Leave ")]):(_vm.isLWOP)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Leave Without Pay ")]):(_vm.isUSL)?_c('div',{staticClass:"timesheet_day_mobile__timer"},[_vm._v(" Unpaid Sick Leave ")]):(_vm.isClosed)?_c('div',[_c('div',{key:_vm.timerTrigger,staticClass:"timesheet_day_mobile__timer"},[_vm._v(" "+_vm._s(_vm.timer())+" ")]),(_vm.isCurrentDay)?_c('div',{staticClass:"timesheet_day_mobile_timer__description"},[_vm._v(" Start time ")]):_vm._e()]):_c('div',[_c('div',{key:_vm.timerTrigger,staticClass:"timesheet_day_mobile__timer"},[_vm._v(" "+_vm._s(_vm.timer())+" ")]),(!_vm.isClosed)?_c('div',{staticClass:"timesheet_day_mobile_timer__description"},[_vm._v(" Stop timer ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }