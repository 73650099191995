


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {WorkspaceType} from "@/dto/auth/Workspace";
import {SLR} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import BusinessSLR from "@/components/payroll/sickLeave/slr/BusinessSLR.vue";
import EmploymentSLR from "@/components/payroll/sickLeave/slr/EmploymentSLR.vue";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {EmploymentSLR, BusinessSLR}
})
export default class SickLeaveRequest extends Vue {

	@Prop()
	private slr!: SLR

}
