













import {Vue, Component} from 'vue-property-decorator';
import {VCR} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import VacationRequest from "@/components/payroll/vacation/vcr/VacationRequest.vue";
import BusinessVCR from "@/components/payroll/vacation/vcr/BusinessVCR.vue";

@Component({
	components: {BusinessVCR, VacationRequest}
})
export default class VacationRequestByQueryId extends Vue {

	private vcr: VCR | null = null;

	public created() {
		this.loadByQueryId()
	}

	private loadByQueryId() {
		Application.startLoading();
		VacationService.getVcrById(this.$route.params.vcrId, this.$wss.getCurrent.id).then(
			res => {
				this.vcr = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}


}
