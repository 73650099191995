
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DecisionSLR, SLRStatus} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import {prettyEnum} from "../../../../utils/StringUtils";
import moment, {Moment} from "moment-timezone";

@Component({
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	},
	computed: {
		SLRStatus() {
			return SLRStatus
		}
	}
})
export default class SLRData extends Vue {

	@Prop()
	private slr!: DecisionSLR

	resolveColor(): string {
		switch (this.slr.status) {
			case SLRStatus.APPROVED:
			case SLRStatus.COMPLETED:return "#3ECB7F"
			case SLRStatus.REQUESTED:return "#FEDC00"
			case SLRStatus.REJECTED:return "#E14C44"
			case SLRStatus.CANCELLED: return "#818181"
		}
	}

}
