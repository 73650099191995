







import {Vue, Component, Prop} from 'vue-property-decorator';
import VCRData from "@/components/payroll/vacation/vcr/VCRData.vue";
import {ChangeStatusVCRRequestPayload, VCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";

@Component({
	computed: {
		VCRStatus() {
			return VCRStatus
		}
	},
	components: {VCRData}
})
export default class EmploymentVCR extends Vue {

	@Prop()
	private vcr!: VCR


	private cancel() {
		Application.startLoading()
		VacationService.changeStatus(
			new ChangeStatusVCRRequestPayload(
				{
					requestId: this.vcr.id,
					employmentId: this.vcr.requester.id,
					status: VCRStatus.CANCELLED
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("cancel")
			},
			err => processError(err, this)
		)
	}
}
