
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {VCR} from "@/dto/payroll/VacationDTO";
import VCRData from "@/components/payroll/vacation/vcr/VCRData.vue";
import BusinessVCR from "@/components/payroll/vacation/vcr/BusinessVCR.vue";
import {WorkspaceType} from "@/dto/auth/Workspace";
import EmploymentVCR from "@/components/payroll/vacation/vcr/EmploymentVCR.vue";
import VacationService from "@/services/payroll/VacationService";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {EmploymentVCR, BusinessVCR, VCRData}
})
export default class VacationRequest extends Vue {

	@Prop()
	private vcr!: VCR

}
