

















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ApproveRequestPayload, RejectRequestPayload, VCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import VCRData from "@/components/payroll/vacation/vcr/VCRData.vue";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import PortalTextarea from "@/components/common/PortalTextarea.vue";

@Component({
	computed: {
		VCRStatus() {
			return VCRStatus
		}
	},
	components: {PortalTextarea, VCRData}
})
export default class BusinessVCR extends Vue {

	@Prop()
	private vcr!: VCR

	private comment: string | null = null

	private reject() {
		Application.startLoading()
		VacationService.rejectRequest(
			new RejectRequestPayload(
				{
					requestId: this.vcr.id,
					companyId: this.$wss.getCurrent.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("reject")
			},
			err => processError(err, this)
		)
	}

	private approve() {
		Application.startLoading()
		VacationService.approveRequest(
			new ApproveRequestPayload(
				{
					requestId: this.vcr.id,
					companyId: this.$wss.getCurrent.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("approve")
			},
			err => processError(err, this)
		)
	}

}
