import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import FileMetaDTO from "@/dto/files/FileMetaDTO";

@Module({namespaced: true})
class ViewedDocument extends VuexModule {

    public viewedDocument: FileMetaDTO | null = null;
    public resolveOpenViewedDocumentModal = true;

    @Mutation
    public setViewedDocument(value: FileMetaDTO): void {
        this.viewedDocument = value;
    }

    @Mutation
    public setResolveOpenViewedDocumentModal(value: boolean): void {
        this.resolveOpenViewedDocumentModal = value;
    }

}

export default ViewedDocument;
