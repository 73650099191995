import Workspace, {fromCompany, fromPerson, fromSublimeEmployee, getPathFromWorkspaceType, WorkspaceType} from "@/dto/auth/Workspace";
import UserDTO from "@/dto/UserDTO";
import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import AdminService from "@/services/AdminService";
import Auth from "@/state/Auth";

const storedUser = localStorage.getItem('user');

class Workspaces {

    private current: Workspace | null = this.getInitialWorkspace();

    public accessible: Array<Workspace> = this.getAccessibleWorkspaces();

    setUser(user: UserDTO | null) {
        this.resetWorkspace();
    }

    getInitialWorkspace(): Workspace | null {
        if (!Auth.getUser) {
            dropStoredWorkspace()
            return null;
        }
        if (Auth.getUser.sublimeEmployee) {
            const ws = fromSublimeEmployee(Auth.getUser.sublimeEmployee);
            storeWorkspace(ws)
            return ws;
        }
        let ws = loadStoredWorkspace();
        if (!ws) {
            if (Auth.getUser.sublimeEmployee) {
                ws = fromSublimeEmployee(Auth.getUser.sublimeEmployee);
            } else if (Auth.getUser.person) {
                ws = fromPerson(Auth.getUser.person);
            } else {
                ws = fromCompany(Auth.getUser.companies[0])
            }
        }
        storeWorkspace(ws)
        return ws;
    }

    isOwnWorkspace(ws: Workspace): boolean {
        if (!Auth.getUser) {
            return false;
        } else {
            return ws.userId == Auth.getUser.id || ws.userId == Auth.getUser.linkedUserId;
        }
    }

    getAccessibleWorkspaces(): Array<Workspace> {
        const res = new Array<Workspace>();
        if (Auth.getUser?.sublimeEmployee) {
            res.push(fromSublimeEmployee(Auth.getUser.sublimeEmployee));
        }
        if (Auth.getUser?.person) {
            res.push(fromPerson(Auth.getUser.person));
        }
        if (Auth.getUser?.companies) {
            res.push(...Auth.getUser?.companies.map(c => fromCompany(c)));
        }
        if (this.isUserSublimeEmployee && this.current && !this.isOwnWorkspace(this.current)) {
            res.push(this.current);
        }
        return res;
    }

    get isUserSublimeEmployee(): boolean {
        return !!Auth.getUser?.sublimeEmployee;
    }

    changeWorkspace(ws: Workspace) {
        if (this.isWorkspaceAccessible(ws)) {
            this.setWorkspace(ws);
        } else {
            this.resetWorkspace();
        }
    }

    setWorkspace(ws: Workspace) {
        this.current = ws;
        storeWorkspace(ws);
        this.accessible = this.getAccessibleWorkspaces();
    }

    resetWorkspace() {
        this.current = this.getInitialWorkspace();
        storeWorkspace(this.current);
        this.accessible = this.getAccessibleWorkspaces();

    }

    isWorkspaceAccessible(ws: Workspace): boolean {
        if (!this.isUserSublimeEmployee) {
            return this.isOwnWorkspace(ws);
        } else {
            return true;
        }
    }

    extractWorkspaceIfPresent(id: number, type: WorkspaceType): Workspace | null {
        if (!Auth.getUser) {
            return null;
        }
        if (type == WorkspaceType.SUBLIME_EMPLOYEE && Auth.getUser.sublimeEmployee?.id == id) {
            return fromSublimeEmployee(Auth.getUser.sublimeEmployee);
        } else if (type == WorkspaceType.PERSON && Auth.getUser.person?.id == id) {
            return fromPerson(Auth.getUser.person);
        } else if (type == WorkspaceType.COMPANY) {
            return Auth.getUser.companies.filter(c => c.id === id).map(c => fromCompany(c)).pop() || null;
        }
        return null;
    }

    async loadWorkspace(id: number, type: WorkspaceType) {
        const extracted = this.extractWorkspaceIfPresent(id, type);
        if (extracted) {
            this.changeWorkspace(extracted);
        } else if (Auth.getUser?.sublimeEmployee) {
            switch (type) {
                case WorkspaceType.PERSON:
                    await this.loadPersonWorkspace(id);
                    break;
                case WorkspaceType.COMPANY:
                    await this.loadCompanyWorkspace(id);
                    break;
                case WorkspaceType.SUBLIME_EMPLOYEE:
                    await this.loadSublimeEmployeeWorkspace(id);
                    break;
            }
        }
    }

    async loadPersonWorkspace(id: number) {
        try {
            const resp = await PersonService.getById(id);
            this.changeWorkspace(fromPerson(resp.data));
        } catch (err) {
            console.log(JSON.stringify(err));
        }
    }

    async loadCompanyWorkspace(id: number) {
        try {
            const resp = await CompanyService.getById(id);
            this.changeWorkspace(fromCompany(resp.data));
        } catch (err) {
            console.log(JSON.stringify(err));
        }
    }

    async loadSublimeEmployeeWorkspace(id: number) {
        try {
            const resp = await AdminService.getEmployee(id);
            this.changeWorkspace(fromSublimeEmployee(resp.data));
        } catch (err) {
            console.log(JSON.stringify(err));
        }
    }

    isCurrentWorkspace(id: number, type: WorkspaceType) {
        return this.current && this.current.id == id && this.current?.type == type;
    }

    public get getCurrent(): Workspace {
        return this.current as Workspace;
    }

    public get getCurrentIdOrNull(): number | null {
        return this.current?.id || null;
    }

    public get getAccessible() {
        return this.accessible;
    }

    public get isCurrentWorkspaceSublimeEmployee(): boolean {
        return this.current?.type == WorkspaceType.SUBLIME_EMPLOYEE;
    }

    public get isCurrentWorkspaceCompany(): boolean {
        return this.current?.type == WorkspaceType.COMPANY;
    }

    public get isCurrentWorkspacePerson(): boolean {
        return this.current?.type == WorkspaceType.PERSON;
    }

    public get getPathElement(){
        return getPathFromWorkspaceType(this.current?.type as WorkspaceType);
    }

    public get getCurrentType(): WorkspaceType | null {
        return this.current?.type || null;
    }

    public get hasWorkspace(): boolean {
        return !!this.current;
    }

    public async refreshWorkspace(){
        const id = this.current?.id as number;
        switch (this.getCurrentType) {
            case WorkspaceType.PERSON:
                await this.loadPersonWorkspace(id);
                break;
            case WorkspaceType.COMPANY:
                await this.loadCompanyWorkspace(id);
                break;
            case WorkspaceType.SUBLIME_EMPLOYEE:
                await this.loadSublimeEmployeeWorkspace(id);
                break;
        }
    }

}

export default new Workspaces();

function storeWorkspace(ws: Workspace | null) {
    if (ws) {
        localStorage.setItem('workspace', JSON.stringify(ws));
    } else {
        dropStoredWorkspace();
    }
}

function loadStoredWorkspace(): Workspace | null {
    const ws = localStorage.getItem('workspace');
    return ws ? JSON.parse(ws) : null;
}

function dropStoredWorkspace() {
    localStorage.removeItem('workspace');
}
