import {IEmployment} from "@/dto/payroll/IEmployment";
import {SickLeave, SickLeaveBalance} from "@/components/payroll/sickLeave/SickLeave";

export enum SLRStatus {
    REQUESTED = "REQUESTED",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED"
}

export class ChangeStatusPayload {
    requestId: number | null = null;
    employmentId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<ChangeStatusPayload>) {
        Object.assign(this, init);
    }
}

export class CreateCommentPayload extends ChangeStatusPayload {
    constructor(init?: Partial<CreateCommentPayload>) {
        super(init)
    }
}

export class CreateSLRPayload {
    startDate: Date | null = null;
    endDate: Date | null = null;
    employmentId: number | null = null;
    paid = false
    comment: string | null = null;

    constructor(init?: Partial<CreateSLRPayload>) {
        Object.assign(this, init)
    }
}

export class SLRFilter {
    employerId: number | null = null;
    employmentsId: Array<number> | null = null;
    page = 0
    size = 20;
    status: SLRStatus | null = null
    startDate: Date | null = null;
    endDate: Date | null = null;

    constructor(init?: Partial<SLRFilter>) {
        Object.assign(this, init)
    }
}

export class SLRComment {
    content!: string
    sendBy!: string
    requester!: boolean
    createAt!: Date
}

export class SLR {
    id!: number;
    status!: SLRStatus;
    requester!: IEmployment
    comments!: Array<SLRComment>
    sickLeave!: SickLeave
    assignee!: Account
    duration!: string
}

export class DecisionSLR extends SLR {
    sickLeaveBalance!: SickLeaveBalance
    enoughBalance!: boolean
    paidPolicy!: boolean
}

