




























import {Vue, Component, Prop, Model} from 'vue-property-decorator';
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import Workspaces from "@/state/Workspaces";
import {PropType} from "vue";
import ViewedDocumentModal from "@/components/util/ViewedDocumentModal.vue";
import { namespace } from "vuex-class";
import Application from "@/state/Application";

const ViewedDocumentModule = namespace('ViewedDocument');

@Component
export default class SingleFileHolder extends Vue {

  @ViewedDocumentModule.Mutation
  private setViewedDocument!: (file: FileMetaDTO | null) => void

  @ViewedDocumentModule.Mutation
  private setResolveOpenViewedDocumentModal!: (value: boolean) => void

  @ViewedDocumentModule.State
  private viewedDocument!: FileMetaDTO | null

  @ViewedDocumentModule.State
  private resolveOpenViewedDocumentModal!: boolean

  @Model('change', { type: [Object, FileMetaDTO, null] as PropType<FileMetaDTO | null> })
  readonly fileValue!: FileMetaDTO | null;

  @Prop({ default: false })
  confirmationRequired!: boolean;

  @Prop()
  placeholder!: string;

  @Prop({default: (file: FileMetaDTO) => Vue.prototype.$void})
  onFileUploaded!: (file: FileMetaDTO) => void;

  @Prop()
  onFileDeleted!: () => void;

  @Prop({default: true})
  showFileName!: boolean;

  @Prop({default: true})
  deletable!: boolean;

  @Prop({default: false})
  required!: boolean;

  @Prop({default: true})
  isValidFile!: boolean;

  uploadFile(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.addEventListener('change', async () => {
      if (fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];
        const uploadedFile: FileMetaDTO = await this.uploadToServer(file);
        this.setViewedDocument(uploadedFile);
        if (this.confirmationRequired && this.resolveOpenViewedDocumentModal) {
          this.$modal.show(
            ViewedDocumentModal,
            {
              placeholder: this.placeholder || "Upload file",
              repeatUploadFile: () => fileInput.click(),
              saveUploadFile: () => this.saveUploadFile(this.viewedDocument!)
            },
            {},
            {
              'before-open': () => Application.startLoading(),
              'opened': () => Application.stopLoading(),
              'before-close': () => this.setResolveOpenViewedDocumentModal(true),
            }
          );
        }
        if (!this.confirmationRequired) {
          this.saveUploadFile(this.viewedDocument!);
        }
      }
    });
    fileInput.click();
  }

  saveUploadFile(uploadedFile: FileMetaDTO) {
    this.$emit('change', uploadedFile);
    this.onFileUploaded(uploadedFile);
  }

  async uploadToServer(file: File): Promise<FileMetaDTO> {
    return FileService.uploadFile(file, Workspaces.getCurrent ? Workspaces.getCurrent.userId : null, null).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
    );
  }

  deleteFile(): void {
    this.$emit('change', null);
    this.setViewedDocument(null);
    this.onFileDeleted();
  }

}
