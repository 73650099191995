import { render, staticRenderFns } from "./SLRData.vue?vue&type=template&id=14364a78&scoped=true&"
import script from "./SLRData.vue?vue&type=script&lang=ts&"
export * from "./SLRData.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14364a78",
  null
  
)

export default component.exports