

























import {Component, Prop, Vue} from "vue-property-decorator";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import { namespace } from "vuex-class";

const ViewedDocumentModule = namespace('ViewedDocument');

@Component<ViewedDocumentModal>({
  components: { EmbeddedFile },
  watch: {
    viewedDocument() {
      this.loadImg();
    }
  }
})
export default class ViewedDocumentModal extends Vue {

  @ViewedDocumentModule.State
  private viewedDocument!: FileMetaDTO | null;

  @ViewedDocumentModule.Mutation
  private setResolveOpenViewedDocumentModal!: (value: boolean) => void;

  @Prop()
  placeholder!: string;

  @Prop()
  repeatUploadFile!: () => void;

  @Prop()
  saveUploadFile!: (uploadedFile: FileMetaDTO) => void;

  private srcForImg: string | void = '';

  mounted() {
    this.loadImg();
  }

  private async loadImg() {
    if (!this.isPDF) {
      this.srcForImg = await this.$docUtils.getURLDownloadedFile(this.viewedDocument!.id);
    }
  }

  private closeModal() {
    this.setResolveOpenViewedDocumentModal(true);
    this.$emit('close');
  }

  private repeatFileSelection() {
    this.setResolveOpenViewedDocumentModal(false);
    this.repeatUploadFile();
  }

  private confirm() {
    this.saveUploadFile(this.viewedDocument!);
    this.closeModal();
  }

  private get isPDF() {
    return this.viewedDocument?.extension === 'pdf';
  }
}
