
























import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {CreateSLRPayload} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import {SickLeaveBalance} from "@/components/payroll/sickLeave/SickLeave";
import Application from "@/state/Application";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {processError} from "@/utils/ComponentUtils";
import PortalTextarea from "@/components/common/PortalTextarea.vue";

@Component({
	components: {PortalTextarea, PortalSelect, PortalCheckbox, PortalDate}
})
export default class SickLeaveRequestCreationModal extends Vue {

	@Prop()
	private employment!: IEmployment

	@Prop()
	private callback!: () => void

	private balance: SickLeaveBalance | null = null;

	private req = new CreateSLRPayload(
		{
			employmentId: this.employment.id
		}
	)

	public created() {
		this.loadSickLeaveBalance()
	}

	private loadSickLeaveBalance(): Promise<void> {
		Application.startLoading()
		return SickLeaveService.getSickLeaveBalance(this.employment.id!).then(
			res => {
				res.data.carryover = 0
				this.balance = res.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private create() {
		Application.startLoading()
		SickLeaveService.createRequest(this.req).then(
			() => {
				Application.stopLoading()
				this.callback()
				this.$modal.hideAll()
			},
			err => processError(err, this)
		)
	}

}
