export enum DocumentSigningStatus {
  CREATED = "CREATED",
  PARTIALLY_SIGNED = "PARTIALLY_SIGNED",
  SIGNED = "SIGNED",
}

export enum SigningStatus {
  ASSIGNED ="ASSIGNED",
  SENT = "SENT",
  OPENED = "OPENED",
  SIGNED = "SIGNED",
  DECLINED = "DECLINED",
}

export enum SignableDocumentType {
  FORM_W4_2023 = 'FORM_W4_2023',
  FORM_W9_2024 = 'FORM_W9_2024',
  FORM_W8BEN_2021 = 'FORM_W8BEN_2021',
  COMPANY_PTO_POLICY = 'COMPANY_PTO_POLICY',
  EMPLOYEE_PTO_POLICY = 'EMPLOYEE_PTO_POLICY',
  COMPANY_SICK_LEAVE_POLICY = 'COMPANY_SICK_LEAVE_POLICY',
  EMPLOYEE_SICK_LEAVE_POLICY = 'EMPLOYEE_SICK_LEAVE_POLICY',
  FORM_TR_2000_2021 = 'FORM_TR_2000_2021',
  FORM_8655_2024 = 'FORM_8655_2024',
  FORM_DOS_1556_F_2022 = 'FORM_DOS_1556_F_2022',
  TR_579_CT_2023 = 'TR_579_CT_2023',
  FORM_8879_C = 'FORM_8879_C',
  NYC_579_COR_2023 = 'NYC_579_COR_2023',
  FORM_8879_CRP = 'FORM_8879_CRP',
  FORM_PA_8879_C_2022 = 'FORM_PA_8879_C_2022',
  FORM_PA_8879_P_2023 = 'FORM_PA_8879_P_2023',
  FORM_VA_8879_C_2023 = 'FORM_VA_8879_C_2023',
  FORM_8879_WH = 'FORM_8879_WH',
  NYC_579_UBTP_2023 = 'NYC_579_UBTP_2023',
  FORM_CBT_100_NJ = 'FORM_CBT_100_NJ',
  FORM_1100_DELAWARE_2023 = 'FORM_1100_DELAWARE_2023',
  FORM_IT_204_LL_2023 = 'FORM_IT_204_LL_2023',
  FORM_SC_8453_C_2021 = 'FORM_SC_8453_C_2021',
  FORM_CT_1065_1120_CT = 'FORM_CT_1065_1120_CT',
  F_3115_2022 = 'F_3115_2022',
  FORM_1120A_2024 = 'FORM_1120A_2024'

}

export enum LabelType {
  SIGNATURE = "SIGNATURE",
  NAME = "NAME",
}
