import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth.module";
import Taxation from "./modules/taxation.module";
import App from "./modules/app.module";
import Insurance from "@/store/modules/insurance.module";
import ViewedDocument from "@/store/modules/viewedDocument.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Taxation,
    App,
    Insurance,
    ViewedDocument,
  },
});
