
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DecisionVCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "../../../../utils/StringUtils";

@Component(
	{
		computed: {
			VCRStatus() {
				return VCRStatus
			}
		},
		methods: {
			prettyEnum,
			moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
				return moment(inp, format, language, strict)
			}
		}
	}
)
export default class VCRData extends Vue {

	@Prop()
	private vcr!: DecisionVCR

	resolveColor(): string {
		switch (this.vcr.status) {
			case VCRStatus.APPROVED:
			case VCRStatus.COMPLETED:return "#3ECB7F"
			case VCRStatus.REQUESTED:
			case VCRStatus.IN_PROGRESS:return "#FEDC00"
			case VCRStatus.REJECTED:return "#E14C44"
			case VCRStatus.CANCELLED: return "#818181"
		}
	}

}
