












































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalDate from "@/components/common/PortalDate.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {VacationBalanceDetails, BalanceDetailsFilterForPeriod} from "@/dto/payroll/VacationDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import SickLeaveService from "@/services/payroll/SickLeaveService";

@Component({
	components: {PaginationComponent, PortalDate},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class SickLeaveBalanceDetails extends Vue {
	@Prop()
	employment!: IEmployment

	private balanceDetails: Array<VacationBalanceDetails> = []
	private balanceInHours: number | string | null = null;
	private balanceInDays: number | string | null = null;
	private detailsPages = 0
	private balanceDetailsFilter = new BalanceDetailsFilterForPeriod(
		{
			employmentId: this.employment.id
		}
	)

	public created() {
		this.loadAvailableBalance()
			.then(() => this.loadBalanceDetails())
	}

	private loadBalanceDetailsForPeriod() {
		Application.startLoading()
		SickLeaveService.getBalanceDetailsForPeriod(this.balanceDetailsFilter)
			.then(
				res => {
					this.balanceDetails = res.data.data
					this.detailsPages = res.data.totalPages
					this.balanceInHours = res.data.balanceForPeriodHours
					this.balanceInDays = res.data.balanceForPeriodDays
					Application.stopLoading()
				},
				err => processError(err, this)
			)
	}

	private loadBalanceDetails() {
		Application.startLoading();
		return SickLeaveService.getBalanceDetails(this.balanceDetailsFilter).then(
			res => {
				this.balanceDetails = res.data.data
				this.detailsPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private loadAvailableBalance() {
		Application.startLoading()
		return SickLeaveService.getSickLeaveBalance(this.employment.id!).then(
			res => {
				this.balanceInDays = res.data.totalBalanceDays;
				this.balanceInHours = res.data.totalBalanceHours
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private resetBalanceDetailsForPeriod() {
		this.balanceDetailsFilter = new BalanceDetailsFilterForPeriod(
			{
				employmentId: this.employment.id,
			}
		)
		this.balanceInHours = null;
		this.balanceInDays = null;
		this.loadAvailableBalance().then(
			() => this.loadBalanceDetails()
		)
	}

	private changeBalanceDetailsPage(page: number) {
		this.balanceDetailsFilter.page = page - 1;
		if (this.balanceDetailsFilter.startDate || this.balanceDetailsFilter.endDate) {
			this.loadBalanceDetailsForPeriod()
		} else {
			this.loadBalanceDetails()
		}
	}
}
