



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ApproveRequestPayload, RejectRequestPayload, VCR, VCRStatus} from "@/dto/payroll/VacationDTO";
import VCRData from "@/components/payroll/vacation/vcr/VCRData.vue";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {processError} from "@/utils/ComponentUtils";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import {ChangeStatusPayload, CreateCommentPayload, SLR, SLRStatus} from "@/components/payroll/sickLeave/slr/SLR_DTO";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import SLRData from "@/components/payroll/sickLeave/slr/SLRData.vue";

@Component({
	computed: {
		SLRStatus() {
			return SLRStatus
		}
	},
	components: {SLRData, PortalTextarea, VCRData}
})
export default class BusinessSLR extends Vue {

	@Prop()
	private slr!: SLR

	private comment: string | null = null

	private reject() {
		Application.startLoading()
		SickLeaveService.rejectRequest(
			new ChangeStatusPayload(
				{
					requestId: this.slr.id,
					employmentId: this.slr.requester.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("reject")
			},
			err => processError(err, this)
		)
	}

	private approve() {
		Application.startLoading()
		SickLeaveService.approveRequest(
			new ChangeStatusPayload(
				{
					requestId: this.slr.id,
					employmentId: this.slr.requester.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("approve")
			},
			err => processError(err, this)
		)
	}

	private sendComment() {
		Application.startLoading()
		SickLeaveService.createComment(
			new CreateCommentPayload(
				{
					requestId: this.slr.id,
					employmentId: this.slr.requester.id,
					comment: this.comment
				}
			)
		).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.$emit("comment")
				this.comment = null
			},
			err => processError(err, this)
		)
	}

}
